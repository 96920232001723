import React, { useState } from 'react';
import { Button, Popover, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { KeyboardArrowDown, KeyboardArrowUp, Update } from '@mui/icons-material';

import {
  publishBrandsVenuesAssociationsMenu,
  fetchBrandsVenuesAssociations,
} from '../../../../../../store/brands/brandsVenueAssociations';
import { useNotifications } from '../../../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../../../shared/utils/errors';

import StatusTooltip from '../Status/StatusTooltip';

const useStyles = makeStyles((theme) => ({
  popover: {
    '& .MuiPopover-paper': {
      padding: theme.spacing(1),
    },
  },
}));

const VenueActions = ({
  venueAssociationValues,
  menuName,
  isVersionUpToDate,
  handleVenueAssociation,
  brandId,
  directIds = {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { venueId } = venueAssociationValues;
  const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const platforms = [
    directIds.deliverooStoreId && {
      name: 'deliveroo',
      displayName: 'Deliveroo',
    },
    directIds.ubereatsStoreId && {
      name: 'ubereats',
      displayName: 'Uber Eats',
    },
    directIds.justeatStoreId && {
      name: 'justeat',
      displayName: 'Just Eat',
    },
  ].filter(Boolean);

  const handleActionDropdownClose = () => {
    setActionDropdownOpen(false);
    setAnchorEl(undefined);
  };

  const handlePublishClick = async (_event, target) => {
    setLoading(true);
    try {
      const targets = target ? [target] : platforms;
      await dispatch(
        publishBrandsVenuesAssociationsMenu({
          menuName,
          directPublishTargets: targets.map((t) => t.name),
          venueId,
        }),
      );
      setLoading(false);
      await dispatch(fetchBrandsVenuesAssociations(brandId));
      showSuccessNotification(
        `Menu published${target ? ` to ${targets.map((t) => t.displayName).join(', ')}` : ''}`,
      );
    } catch (e) {
      setLoading(false);
      showErrorNotification(getErrorMessage(e));
    }
  };

  if (!isVersionUpToDate)
    return (
      <StatusTooltip title="Update association">
        <IconButton
          color="primary"
          onClick={() => handleVenueAssociation({ venueId, menuName }, true)}
        >
          <Update />
        </IconButton>
      </StatusTooltip>
    );

  const isPublishDisabled = () => Object.keys(directIds).length === 0;

  return (
    <>
      <IconButton
        disabled={loading || isPublishDisabled()}
        color="primary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setActionDropdownOpen(!actionDropdownOpen);
        }}
      >
        {actionDropdownOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </IconButton>
      {actionDropdownOpen && (
        <Popover
          open={actionDropdownOpen}
          onClose={handleActionDropdownClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className={classes.popover}
        >
          <Button
            id={venueId}
            variant="contained"
            color="primary"
            fullWidth
            onClick={(e) => {
              handlePublishClick();
              handleActionDropdownClose(e);
            }}
          >
            Publish All
          </Button>
          {platforms &&
            platforms.length > 0 &&
            platforms.map((target) => (
              <Button
                key={target.name}
                variant="contained"
                color="primary"
                fullWidth
                onClick={(e) => {
                  handlePublishClick(e, target);
                  handleActionDropdownClose();
                }}
                style={{ width: '100%', marginTop: '.5em' }}
              >
                {`Publish to ${target.displayName}`}
              </Button>
            ))}
        </Popover>
      )}
    </>
  );
};

export default VenueActions;
