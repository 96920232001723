import { createSlice } from '@reduxjs/toolkit';
import {
  createAsyncAction,
  asyncInitialState as initialState,
  createAsyncReducerHandlers,
} from '../../utils/async';
import { get } from '../../../api/httpRequest';

const fetchBrandsForVenueApi = async () => {
  const response = await get('/delivery-brands');

  return response.data;
};

export const fetchBrandsForVenue = createAsyncAction(
  'fetch-brands-for-venue',
  fetchBrandsForVenueApi,
);

const venuesBrandsSlice = createSlice({
  name: 'venueBrands',
  initialState,
  reducers: {
    clearVenueBrands(state) {
      delete state.data;
    },
  },
  extraReducers: {
    ...createAsyncReducerHandlers(fetchBrandsForVenue),
  },
});

export const { reducer: venueBrands } = venuesBrandsSlice;
export const { clearVenueBrands } = venuesBrandsSlice.actions;
