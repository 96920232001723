import { deleteBare, getBare, postBare } from '../httpRequest';

export const fetchBrandsVariationStatusApi = async (variationId, brandMenuId) => {
  const isValidBrandMenuId = typeof brandMenuId === 'string';

  const response = await getBare(
    `brands/${variationId}/menus/status${isValidBrandMenuId ? `?brandMenuId=${brandMenuId}` : ''}`,
  );
  return response.data;
};

export const pushBrandsVenuesAssociationsApi = async (variationId, venues) => {
  const response = await postBare(`brands/menus/${variationId}/push`, {
    data: venues,
  });

  return response.data;
};

export const pushAllBrandsVenuesAssociationsApi = async (variationId) => {
  const response = await postBare(`brands/menus/${variationId}/push/all`);
  return response.data;
};

export const publishBrandsVenuesAssociationsMenuApi = async ({
  menuName,
  directPublishTargets,
  venueId,
}) => {
  const response = await postBare(`venues/${venueId}/menus/${menuName}/publish`, {
    data: {
      directPublishTargets,
    },
  });

  return response.data;
};

export const publishAllBrandsVenueAssociationsMenuApi = async (variationId) => {
  const response = await postBare(`brands/menus/${variationId}/publish/all`);

  return response.data;
};

export const removeBrandsVenuesAssociationApi = async ({ variationId, venueId }) => {
  const response = await deleteBare(`brands/menus/${variationId}/${venueId}/association`);
  return response.data;
};
