import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography, Button, Grid, Box, List, ListItemText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { fetchMenuItems, clearMenuItems } from '../../store/menuItems';
import {
  clearVenueMenu,
  clearVenueMenus,
  fetchVenueMenu,
  clearVenueMenuStatus,
  fetchVenueMenuStatus,
} from '../../store/venueMenus';
import { getVenueMenuState, getVenueMenuStatusState } from '../../store/venueMenus/selectors';
import { clearVenueMenuItems, setVenueMenuItems } from '../../store/venueMenuItems';
import { getVenueMenuItemsState } from '../../store/venueMenuItems/selectors';

import Page from '../../components/Page';
import MenuGroups from '../../components/MenuGroups';
import CreateMenuGroupButton from '../../components/CreateMenuGroupButton';

import { getMenuItemsState } from '../../store/menuItems/selectors';
import withVenue from '../../hoc/withVenue';
import MenuExportImport from '../../components/MenuExportImport';
import BackArrow from '../../components/BackArrow';
import {
  getVenuePrioritiesState,
  getVenuesSettingsState,
  isDeliveryOnly,
} from '../../store/venues/selectors';
import { clearVenuePriorities, fetchVenuePriorities } from '../../store/venues';
import shouldLoad from '../../shared/utils/shouldLoad';
import useRoles from '../../hooks/useRoles';
import { getServiceStatusState } from '../../store/serviceStatus/selectors';
import MenuWarning from './MenuWarning';
import { fetchServiceStatus } from '../../store/serviceStatus';
import PageHeader from '../../components/PageHeader';
import PublishMenu from '../../components/PublishMenu';
import CustomWarningBanner from '../../components/CustomWarningBanner';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  info: {
    marginRight: 30,
  },
  warning: {
    marginTop: 15,
    marginBottom: 15,
  },
  bannerTitle: {
    ...theme.customFonts.smallBold,
    marginRight: 8,
  },
  listItemContainer: {
    '& span': {
      display: 'flex',
    },
  },
  listTitle: {
    ...theme.customFonts.xSmall,
    marginRight: 8,
  },
  listValue: {
    ...theme.customFonts.xSmall,
  },
  boxContainer: {
    display: 'flex',
  },
  publishStatusValue: {
    ...theme.customFonts.small,
  },
}));

const Menu = ({ redirect }) => {
  const { menuName } = useParams();
  const { isAdmin, isRoleAtLeastManager } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;
  const classes = useStyles();
  const dispatch = useDispatch();
  const venueMenuState = useSelector(getVenueMenuState);
  const venueMenuStatusState = useSelector(getVenueMenuStatusState);
  const venueMenuItemsState = useSelector(getVenueMenuItemsState);
  const menuItemsState = useSelector(getMenuItemsState);
  const venuePrioritiesState = useSelector(getVenuePrioritiesState);
  const serviceStatusState = useSelector(getServiceStatusState);
  const venueSettingsState = useSelector(getVenuesSettingsState);

  const { data: venueSettings } = venueSettingsState;
  const { loading: venueMenuLoading, data: venueMenu, error: errorVenueMenu } = venueMenuState;
  const { data: menuStatus = {}, loading: venueMenuStatusLoading } = venueMenuStatusState;
  const { data: venueMenuItems, error: errorVenueMenuItems } = venueMenuItemsState;
  const { data: menuItems, error: errorMenuItems } = menuItemsState;
  const { data: serviceStatus, error: errorServiceStatus } = serviceStatusState;
  const {
    loading: prioritiesLoading,
    data: venuePriorities,
    error: prioritiesError,
  } = venuePrioritiesState;
  const {
    displayMenuName,
    description,
    importUploadUrl,
    readonly,
    deliverooStoreId,
    justeatStoreId,
    ubereatsStoreId,
  } = venueMenu || {};
  const { publishStatus, publishData } = menuStatus || {};
  const isDeliveryIntegrated = deliverooStoreId || justeatStoreId || ubereatsStoreId;
  const isReadonly = readonly === true;
  const [expandAll, setExpandAll] = useState(false);
  const isSessionsBrandsEnabled = !!venueSettings?.find(
    (setting) => setting.settingName === 'SESSIONS_BRAND_ENABLED' && setting.value === true,
  );

  useEffect(() => {
    if (shouldLoad(serviceStatusState)) dispatch(fetchServiceStatus());
    if (shouldLoad(venueMenuState)) dispatch(fetchVenueMenu(menuName));
    if (shouldLoad(menuItemsState)) dispatch(fetchMenuItems());
    if (shouldLoad(venuePrioritiesState)) dispatch(fetchVenuePriorities());
  }, [
    menuName,
    dispatch,
    venueMenuState,
    menuItemsState,
    venuePrioritiesState,
    serviceStatus,
    serviceStatusState,
    readonly,
  ]);

  const getPlatformTitle = (key) => {
    switch (key) {
      case 'Deliveroo':
        return deliverooStoreId ? `${key}: ${deliverooStoreId}` : key;
      case 'Just Eat':
        return justeatStoreId ? `${key}: ${justeatStoreId}` : key;
      case 'Uber Eats':
        return ubereatsStoreId ? `${key}: ${ubereatsStoreId}` : key;
      default:
        return key;
    }
  };

  useEffect(() => {
    if (venueMenu && isDeliveryIntegrated && shouldLoad(venueMenuStatusState))
      dispatch(fetchVenueMenuStatus(venueMenu));
  }, [dispatch, venueMenuStatusState, venueMenu, isDeliveryIntegrated]);

  useEffect(() => {
    if (venueMenu) {
      dispatch(setVenueMenuItems(venueMenu));
    }
  }, [dispatch, venueMenu]);

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <BackArrow redirect={redirect} text="Menus" />
          <Typography variant="h1" component="h1">
            {displayMenuName}
          </Typography>
          {description && <Typography variant="h2">{description}</Typography>}
        </div>
      </PageHeader>
      <Page
        fullWidth
        error={
          errorVenueMenuItems ||
          errorMenuItems ||
          errorVenueMenu ||
          prioritiesError ||
          errorServiceStatus
        }
        loading={venueMenuLoading || prioritiesLoading}
      >
        {venueMenuItems && menuItems && (
          <>
            {serviceStatus && <MenuWarning serviceStatus={serviceStatus} />}
            <Box component="header">
              <Grid container direction="row" justifyContent="flex-end" spacing={4}>
                <Grid item xs={12} md={7} component="aside">
                  <Grid container justifyContent="flex-end" spacing={1}>
                    {isAdmin() && isDeliveryIntegrated && (
                      <>
                        <Grid item xs={12} md={6} />
                        <Grid item xs={12} md={6}>
                          <PublishMenu
                            menuName={menuName}
                            directIds={{
                              ...(deliverooStoreId && { deliverooStoreId }),
                              ...(justeatStoreId && { justeatStoreId }),
                              ...(ubereatsStoreId && { ubereatsStoreId }),
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    {isAdmin() && (
                      <Grid item xs={12} md={6}>
                        <MenuExportImport
                          type="import"
                          menuName={menuName}
                          uploadUrl={importUploadUrl}
                          disabled={!isRoleAtLeastManager() || permissionReadOnly}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <MenuExportImport
                        type="export"
                        menuName={menuName}
                        disabled={permissionReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CreateMenuGroupButton
                        menuName={menuName}
                        disabled={isReadonly || !isRoleAtLeastManager() || permissionReadOnly}
                        venuePriorities={venuePriorities}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {publishStatus &&
                publishData &&
                Object.entries(publishStatus).length !== 0 &&
                !venueMenuStatusLoading && (
                  <div className={classes.warning}>
                    <CustomWarningBanner
                      title="Direct Delivery Details"
                      titleFont="mediumBold"
                      titleColor="#000000"
                      backgroundColor="#FFE87A"
                      iconColor="#000000"
                      borderRadius="8px"
                    >
                      <Box>
                        {publishStatus && (
                          <>
                            {Object.entries(publishStatus).map(([key, { status, message }]) => (
                              <List key={key} sx={{ p: 0 }}>
                                <ListItemText>
                                  <Typography className={classes.bannerTitle}>
                                    {getPlatformTitle(key)}
                                  </Typography>
                                </ListItemText>{' '}
                                <ListItemText className={classes.listItemContainer}>
                                  <Grid container>
                                    <Grid item xs={4} md={2}>
                                      <Typography className={classes.listTitle}>Status:</Typography>{' '}
                                    </Grid>
                                    <Grid item xs={8} md={10}>
                                      <Typography className={classes.listValue}>
                                        {status}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </ListItemText>
                                {message && typeof message !== 'object' ? (
                                  <ListItemText className={classes.listItemContainer}>
                                    <Grid container>
                                      <Grid item xs={4} md={2}>
                                        <Typography className={classes.listTitle}>
                                          Status message:{' '}
                                        </Typography>{' '}
                                      </Grid>
                                      <Grid item xs={8} md={10}>
                                        <Typography className={classes.listValue}>
                                          {message}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </ListItemText>
                                ) : (
                                  ''
                                )}
                                {publishData && publishData[key] ? (
                                  <>
                                    <ListItemText className={classes.listItemContainer}>
                                      <Grid container>
                                        <Grid item xs={4} md={2}>
                                          <Typography className={classes.listTitle}>
                                            Published at:{' '}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={8} md={10}>
                                          <Typography className={classes.listValue}>
                                            {moment(publishData[key].publishedAt).format(
                                              'YYYY-MM-DD HH:mm',
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </ListItemText>
                                    {publishData[key].healthCheckAt && (
                                      <ListItemText className={classes.listItemContainer}>
                                        <Grid container>
                                          <Grid item xs={4} md={2}>
                                            <Typography className={classes.listTitle}>
                                              Last health check:{' '}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={8} md={10}>
                                            <Typography className={classes.listValue}>
                                              {moment(publishData[key].healthCheckAt).format(
                                                'YYYY-MM-DD HH:mm',
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </ListItemText>
                                    )}
                                    <ListItemText className={classes.listItemContainer}>
                                      <Grid container>
                                        <Grid item xs={4} md={2}>
                                          <Typography className={classes.listTitle}>
                                            Live status:{' '}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={8} md={10}>
                                          <Typography className={classes.listValue}>
                                            {publishData[key].isLive ? 'Live' : 'Not Live'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </ListItemText>
                                  </>
                                ) : (
                                  ''
                                )}
                              </List>
                            ))}
                          </>
                        )}
                      </Box>
                      <br />
                      {publishStatus && (
                        <Box className={classes.boxContainer}>
                          <Typography className={classes.bannerTitle}>
                            Available Delivery Platforms:
                          </Typography>{' '}
                          <Typography className={classes.publishStatusValue}>
                            {Object.entries(publishStatus)
                              .map(([key]) => key)
                              .join(', ')}
                          </Typography>
                        </Box>
                      )}
                    </CustomWarningBanner>
                  </div>
                )}
              <Grid container justifyContent="space-between" alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography paragraph className={classes.info}>
                    Create new sections in your menu, add or remove products from menu sections,
                    rename and reorder sections and items in each section, set up serving times for
                    sections
                  </Typography>
                </Grid>
                <Grid item component="aside" xs={12}>
                  <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<KeyboardArrowDownIcon />}
                      onClick={() => setExpandAll(!expandAll)}
                      to="/menus/add"
                    >
                      {expandAll ? 'Collapse All' : 'Expand All'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <MenuGroups
              expandAll={expandAll}
              isSessionsBrandsEnabled={isSessionsBrandsEnabled}
              isReadonly={isReadonly || permissionReadOnly}
            />
            {isAdmin() ||
              (!isReadonly && (
                <CreateMenuGroupButton
                  menuName={menuName}
                  disabled={!isRoleAtLeastManager() || permissionReadOnly}
                  venuePriorities={venuePriorities}
                />
              ))}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(Menu, '/menus', [
  clearVenueMenuItems,
  clearVenuePriorities,
  clearVenueMenus,
  clearVenueMenuStatus,
  clearVenueMenu,
  clearMenuItems,
]);
