import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { TextField } from 'formik-mui';
import { DragDropContext } from 'react-beautiful-dnd';

import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  Autocomplete,
  TextField as MuiTextField,
  Alert,
} from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material';

import { move } from 'ramda';
import { nanoid } from 'nanoid';
import { upperFirst } from 'lodash';
import CustomDialog from '../../CustomDialog/index';
import Droppable from '../../DragAndDrop/Droppable';
import Draggable from '../../DragAndDrop/Draggable';
import { getBrandsIngredientsState } from '../../../store/brands/brandsIngredients/selectors';
import { getBrandsMenuItemsState } from '../../../store/brands/brandsMenuItems/selectors';
import AddIngredient from '../../../pages/Brands/BrandsIngredients/AddIngredient';
import { fetchBrandsIngredients } from '../../../store/brands/brandsIngredients';
import { fetchBrandsMenuItems } from '../../../store/brands/brandsMenuItems';
import shouldLoad from '../../../shared/utils/shouldLoad';

const useStyles = makeStyles((theme) => ({
  title: { marginTop: theme.spacing(3), marginBottom: theme.spacing(2) },
  optionField: {
    paddingLeft: theme.spacing(6),
  },
  dragIcon: {
    left: 0,
    top: '23.5%',
  },
  ingredientAutocomplete: {
    marginTop: 16,
  },
}));

const upperAll = (str) =>
  str
    .split(' ')
    .map((word) => upperFirst(word))
    .join(' ');

const sortOptions = (options, compareKey = 'ingredientName') =>
  options.sort((a, b) => a[compareKey]?.localeCompare(b[compareKey]));

const getLabel = (optionId) => {
  if (optionId === '') return 'Ingredient or Item';
  if (optionId.split('-')[0] === 'pai') return 'Item';
  return 'Ingredient';
};

const BrandsModifierOptions = ({
  itemOptions,
  allowReordering,
  onReorder,
  setFieldValue,
  readonly,
  brandId,
  mealDealError,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data: options } = useSelector(getBrandsIngredientsState);
  const { data: menuItems } = useSelector(getBrandsMenuItemsState);
  const [showDuplicateHelperText, setShowDuplicateHelperText] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems);
  const [isSizeDialogOpen, setIsSizeDialogOpen] = useState(false);
  const [updatingOption, setUpdatingOption] = useState(null);
  const [newSize, setNewSize] = useState(null);

  const onDragEnd = async ({ destination, source }) => {
    if (!destination) {
      return;
    }
    if (onReorder)
      onReorder({
        itemOptions: move(source.index, destination.index, itemOptions),
      });
    setFieldValue('modifierItems', move(source.index, destination.index, itemOptions));
  };

  // after adding a new ingredient from the drop down, clear temporary state that allowed
  // immediate selection in the dropdown.
  useEffect(() => {
    if (updatingOption && newSize) {
      const updatingOptionObj =
        updatingOption &&
        newSize &&
        options.find((option) => option.ingredientName === upperAll(newSize.ingredientName));
      const index = updatingOption.split('.')[1];
      setFieldValue(`modifierItems.${index}.itemName`, updatingOptionObj.ingredientName);
      setFieldValue(`modifierItems.${index}.itemId`, updatingOptionObj.ingredientId);
      setUpdatingOption(null);
      setNewSize(null);
    }
  }, [updatingOption, newSize, options, setFieldValue]);

  useEffect(() => {
    if (shouldLoad(getBrandsIngredientsState)) {
      dispatch(fetchBrandsIngredients());
    }
    if (shouldLoad(getBrandsMenuItemsState)) {
      dispatch(fetchBrandsMenuItems());
    }
  }, [dispatch]);

  useEffect(() => {
    if (options) {
      const filterOptions = options.filter((option) => option.brandId === brandId);
      setFilteredOptions(sortOptions(filterOptions));
    }
    if (menuItems) {
      const filterMenuItems = menuItems.filter((menuItem) => menuItem.brandId === brandId);
      setFilteredMenuItems(filterMenuItems);
    }
  }, [options, brandId, menuItems]);

  useEffect(() => {
    if (options) {
      const optionIsThere = options.findIndex((e) => e.itemName === newSize?.itemName);
      if (!isSizeDialogOpen && newSize?.itemName && optionIsThere !== -1) {
        if (updatingOption) {
          setFieldValue(updatingOption, newSize?.itemName);
        }
      }
    }
  }, [newSize, options, isSizeDialogOpen, setFieldValue, updatingOption]);

  const handleCloseSizeDialog = async (localNewSize) => {
    await dispatch(fetchBrandsIngredients());
    setNewSize(localNewSize);
    setIsSizeDialogOpen(false);
  };

  const itemsWithIngredientId = useMemo(
    () =>
      filteredMenuItems &&
      filteredMenuItems.map((item) => ({ ...item, itemId: `pai-${item.itemId}`, isItem: true })),
    [filteredMenuItems],
  );

  const searchableOptions = useMemo(() => {
    const sortedOptions = filteredOptions && sortOptions([...filteredOptions]);
    const sortedItems =
      itemsWithIngredientId && sortOptions([...itemsWithIngredientId], 'itemName');

    return sortedOptions && sortedItems && [...sortedOptions, ...sortedItems];
  }, [filteredOptions, itemsWithIngredientId]);

  if (loading || !searchableOptions) {
    return (
      <Box sx={{ my: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  return (
    <>
      <FieldArray name="modifierItems">
        {({ push, remove, form }) => (
          <>
            <Typography className={classes.title} variant="h4">
              Items and ingredients
            </Typography>
            {mealDealError && <Alert severity="error">{mealDealError}</Alert>}
            {searchableOptions && searchableOptions?.length === 0 && (
              <Typography variant="body2">
                No ingredients or items found {brandId && ' for the selected brand'}
              </Typography>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                type="droppable_options"
                isDropDisabled={!allowReordering}
              >
                <>
                  {itemOptions.map((itemOption, index) => (
                    <Draggable
                      key={itemOption.itemId.length > 0 ? itemOption.itemId : nanoid()}
                      draggableId={`option${itemOption.itemId}`}
                      index={index}
                      isDragDisabled={!allowReordering}
                      className={classes.optionField}
                      dragIconClassName={classes.dragIcon}
                    >
                      <Grid container key={itemOption.itemId} spacing={1}>
                        <Grid item xs={4} sm={6}>
                          <Field
                            className={classes.ingredientAutocomplete}
                            component={Autocomplete}
                            fullWidth
                            disableClearable
                            options={searchableOptions}
                            getOptionLabel={(option) => option.ingredientName || option.itemName}
                            name={`modifierItems.${index}.itemId`}
                            value={searchableOptions.find(
                              (option) =>
                                option.ingredientId === itemOption.itemId ||
                                option.itemId === itemOption.itemId,
                            )}
                            groupBy={(option) => (option.isItem ? 'Items' : 'Ingredients')}
                            variant="outlined"
                            margin="normal"
                            disabled={readonly || searchableOptions?.length === 0}
                            onChange={(_, value) => {
                              if (
                                !itemOptions.find(
                                  (item) =>
                                    item.itemId === value.ingredientId ||
                                    item.itemId === value.itemId,
                                )
                              ) {
                                setShowDuplicateHelperText(false);
                                form.setFieldValue(
                                  `modifierItems.${index}.itemName`,
                                  value.ingredientName || value.itemName,
                                );
                                form.setFieldValue(
                                  `modifierItems.${index}.itemId`,
                                  value.ingredientId || value.itemId,
                                );
                              } else {
                                form.setFieldValue(`modifierItems.${index}.itemName`, '');
                                form.setFieldValue(`modifierItems.${index}.itemId`, '');
                                setShowDuplicateHelperText(true);
                              }
                            }}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                label={getLabel(itemOption.itemId)}
                                error={showDuplicateHelperText && itemOption.itemId === ''}
                                helperText={
                                  showDuplicateHelperText && itemOption.itemId === ''
                                    ? 'Duplicate selection found, select another item or ingredient'
                                    : null
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <Field
                            type="number"
                            component={TextField}
                            fullWidth
                            inputProps={{ step: '1', min: 1 }}
                            name={`modifierItems.${index}.multiMax`}
                            label="Multiples"
                            variant="outlined"
                            margin="normal"
                            disabled={readonly || searchableOptions?.length === 0}
                          />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <Field
                            type="number"
                            component={TextField}
                            fullWidth
                            inputProps={{ step: '0.01', min: 0 }}
                            name={`modifierItems.${index}.itemPrice`}
                            label="Price (£)"
                            variant="outlined"
                            margin="normal"
                            disabled={readonly || searchableOptions?.length === 0}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            paddingTop: '25px',
                            textAlign: 'center',
                          }}
                        >
                          {itemOptions.length > 1 && (
                            <IconButton
                              disabled={readonly}
                              aria-label="delete"
                              onClick={() => {
                                setShowDuplicateHelperText(false);
                                remove(index);
                              }}
                              size="large"
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Draggable>
                  ))}
                </>
              </Droppable>
            </DragDropContext>
            {!readonly && searchableOptions?.length > 0 && (
              <Button
                color="primary"
                startIcon={<AddCircleOutline style={{ fontSize: 30 }} />}
                sx={{ mt: 2 }}
                onClick={() => {
                  push({ itemName: '', itemPrice: 0, itemId: '', multiMax: 1 });
                }}
              >
                Add Ingredient or Item
              </Button>
            )}
          </>
        )}
      </FieldArray>
      <CustomDialog
        title="Add a new item"
        handleCloseDialog={handleCloseSizeDialog}
        isDialogOpen={isSizeDialogOpen}
      >
        <AddIngredient modalSubmit={handleCloseSizeDialog} modalClose={handleCloseSizeDialog} />
      </CustomDialog>
    </>
  );
};

BrandsModifierOptions.defaultProps = {
  allowReordering: true,
  onReorder: null,
};

BrandsModifierOptions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  itemOptions: PropTypes.array.isRequired,
  allowReordering: PropTypes.bool,
  onReorder: PropTypes.func,
};

export default BrandsModifierOptions;
