import React from 'react';
import StatusTooltip from './StatusTooltip';

const formatTooltipDate = (date) => {
  const dateObj = new Date(date);
  const options = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Intl.DateTimeFormat('en-GB', options).format(dateObj);
};

const getOtterPublishStatus = ({
  otterPublishData = {},
  otterPublishStatus = {},
  platform,
  publishStatus = {},
  publishData = {},
}) => {
  const finalPublishData = publishData[platform] || otterPublishData[platform];
  const finalPublishStatus = publishStatus[platform] || otterPublishStatus[platform] || {};
  if (!finalPublishData || Object.keys(finalPublishStatus).length === 0) {
    return (
      <StatusTooltip title="Not published on platform">
        <span>🔴</span>
      </StatusTooltip>
    );
  }
  if (!finalPublishData) {
    return (
      <StatusTooltip title="Not published on platform">
        <span>🔴</span>
      </StatusTooltip>
    );
  }

  if (finalPublishData?.isPublishedVersionUpToDate) {
    return (
      <StatusTooltip
        title={`Up to Date${
          finalPublishData?.publishedAt &&
          ` publish on
           ${formatTooltipDate(finalPublishData?.publishedAt)}`
        }`}
      >
        <span>🟢</span>
      </StatusTooltip>
    );
  }

  if (finalPublishStatus?.status?.toLowerCase() === 'failed') {
    return (
      <StatusTooltip title={`Publish failed on ${finalPublishStatus?.message}`}>
        <span>❌</span>
      </StatusTooltip>
    );
  }

  return (
    <StatusTooltip
      title={`Out of Date${
        finalPublishData?.publishedAt &&
        ` publish on 
        ${formatTooltipDate(finalPublishData?.publishedAt)}`
      }`}
    >
      <span>🟠</span>
    </StatusTooltip>
  );
};

const getOtterPushStatus = ({ otterPushedAt, otterPushStatus, isLatestVersionPushed }) => {
  if (!otterPushedAt || !otterPushStatus) {
    return (
      <StatusTooltip title="Not pushed to Otter">
        <span>🔴</span>
      </StatusTooltip>
    );
  }

  if (otterPushStatus === 'FAILED') {
    return (
      <StatusTooltip title="Push failed">
        <span>❌</span>
      </StatusTooltip>
    );
  }

  if (otterPushStatus === 'PENDING') {
    return (
      <StatusTooltip title="Push pending">
        <span>🟡</span>
      </StatusTooltip>
    );
  }

  return (
    <StatusTooltip
      title={` ${
        isLatestVersionPushed ? 'Up to Date push on ' : 'Out of Date push on '
      } ${formatTooltipDate(otterPushedAt)}`}
    >
      <span>{isLatestVersionPushed ? '🟢' : '🟠'}</span>
    </StatusTooltip>
  );
};

const getVariationAssociationStatus = ({ isVersionUpToDate }) => {
  if (isVersionUpToDate) {
    return <StatusTooltip title="Latest version associated with venue">✅</StatusTooltip>;
  }

  return <StatusTooltip title="Out of date version associated with venue">🟧</StatusTooltip>;
};

export { getOtterPublishStatus, getOtterPushStatus, getVariationAssociationStatus };
