import { omit } from 'ramda';
import { get, patch } from './httpRequest';

// eslint-disable-next-line import/prefer-default-export
export const fetchServiceStatus = async () => {
  const response = await get(`/services/status`);
  const { data } = response;
  return data;
};

export const updateServiceStatus = async (update) => {
  await patch(`/services/status`, { data: omit(['isOpen', 'isOpenForDelivery'], update) });
};
