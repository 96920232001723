import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, Switch, TextField } from 'formik-mui';

import { Close } from '@mui/icons-material';
import menuTypes from '../../../../shared/constants/menuTypes';
import { getMenuCountsByType } from '../../../../store/venueMenus/selectors';
import { createVenueMenu, fetchVenueMenus, updateVenueMenu } from '../../../../store/venueMenus';
import { useNotifications } from '../../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../../shared/utils/errors';
import getMenuCountError from '../../../../shared/utils/getMenuCountError';
import useRoles from '../../../../hooks/useRoles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    borderRadius: 8,
  },
  dialogTitleWrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-start',
  },
  dialogTitle: {
    ...theme.customFonts.largeBold,
    padding: theme.spacing(2),
    color: '#181C43',
  },
  dialogClostBtn: {
    padding: theme.spacing(2),
  },
  bodyText: {
    ...theme.customFonts.medium,
    color: '#5A7296',
  },
  btnContainer: {
    borderTop: '1px solid #E0E0EB',
    padding: theme.spacing(2),
  },
  cancelBtn: {
    ...theme.customFonts.mediumBold,
    borderRadius: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  saveBtn: {
    ...theme.customFonts.mediumBold,
    borderRadius: 12,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    background: theme.customPalette.black,
    color: theme.customPalette.white,
    '&:hover': {
      background: theme.customPalette.midnight,
    },
  },
  activeSwitch: {
    '& .MuiFormControlLabel-label': {
      ...theme.customFonts.medium,
      color: '#343860',
    },
  },
  section: {
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-formControl': {
      borderColor: '#D0CFDF',
      borderRadius: 12,
    },
  },
  formLabel: {
    ...theme.customFonts.small,
    marginBottom: theme.spacing(0.25),
    color: '#343860',
  },
  textField: {
    ...theme.customFonts.medium,
    color: '#343860',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  errorText: {
    marginTop: theme.spacing(0.25),
    color: '#CA1D2A',
  },
}));

const EditMenuSchema = Yup.object().shape({
  displayMenuName: Yup.string().required('Menu name is required'),
  description: Yup.string().max(120, 'Description is too long'),
  active: Yup.bool().required(),
  type: Yup.string()
    .oneOf(Object.values(menuTypes).map(({ value }) => value))
    .required(),
  otterStoreId: Yup.string(),
});

const truncateString = (str) => {
  if (str.length > 20) {
    return `${str.slice(0, 20)}...`;
  }
  return str;
};

const EditMenu = ({
  open,
  onClose,
  displayMenuName = '',
  description = '',
  menuName = '',
  type = menuTypes.EPOS.value,
  otterStoreId = '',
  otterDeliveryEnabled = true,
  otterPickupEnabled = true,
  kioskEnabled = false,
  active = false,
  mode = 'EDIT',
  deliverooStoreId = '',
  justeatStoreId = '',
  ubereatsStoreId = '',
}) => {
  const { isAdmin } = useRoles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();

  const activeMenuWarning =
    'Customers can only see active menus, only one menu can be active per menu type';
  const deliveryMenuWarning =
    'Customers will not be able to order until delivery is manually turned back on';

  const menuTypeCount = useSelector((state) => getMenuCountsByType(state));

  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
      };

      if (mode === 'CREATE') {
        await dispatch(createVenueMenu(payload));
      } else {
        await dispatch(updateVenueMenu({ menuName, values: payload }));
      }

      onClose();
      await dispatch(fetchVenueMenus());

      showSuccessNotification(`${values.displayMenuName} has been updated successfully`);
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const initialValues = {
    displayMenuName,
    description,
    active,
    type,
    otterStoreId,
    otterPickupEnabled,
    otterDeliveryEnabled,
    kioskEnabled,
    deliverooStoreId,
    justeatStoreId,
    ubereatsStoreId,
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog} maxWidth="xs" fullWidth>
      <Formik
        initialValues={initialValues}
        onSubmit={() => handleSubmit}
        validationSchema={EditMenuSchema}
      >
        {({ dirty, values }) => {
          const showOtterStoreIdField =
            values.type === 'DELIVERY' || values.type === 'DELIVERY_INHOUSE';
          const errorCountLabel = getMenuCountError({
            menuTypeCount,
            type: values.type,
            initialType: initialValues.type,
            isDuplicate: mode === 'EDIT',
          });
          return (
            <>
              <Box className={classes.dialogTitleWrapper}>
                <DialogTitle className={classes.dialogTitle}>
                  {mode === 'CREATE' ? 'Add menu' : `Edit ${truncateString(displayMenuName)}`}
                </DialogTitle>
                <IconButton onClick={onClose} className={classes.dialogClostBtn}>
                  <Close />
                </IconButton>
              </Box>
              <DialogContent sx={{ padding: 2 }}>
                <Form>
                  {mode === 'EDIT' && (
                    <FormGroup className={classes.section}>
                      <FormControlLabel
                        control={
                          <Field component={Switch} color="primary" type="checkbox" name="active" />
                        }
                        className={classes.activeSwitch}
                        label={showOtterStoreIdField ? 'Available for delivery' : 'Active'}
                      />
                    </FormGroup>
                  )}
                  {showOtterStoreIdField && !values.active && mode === 'EDIT' && (
                    <Typography
                      variant="body1"
                      className={`${classes.bodyText} ${classes.section}`}
                    >
                      {deliveryMenuWarning}
                    </Typography>
                  )}
                  {!showOtterStoreIdField && mode === 'EDIT' && (
                    <Typography
                      variant="body1"
                      className={`${classes.bodyText} ${classes.section}`}
                    >
                      {activeMenuWarning}
                    </Typography>
                  )}
                  <FormGroup className={classes.section}>
                    <Typography variant="body2" className={classes.formLabel}>
                      Menu name*
                    </Typography>
                    <Field
                      component={TextField}
                      inputProps={{ className: classes.textField }}
                      name="displayMenuName"
                      disabled={initialValues.type === menuTypes.DELIVERY.value}
                      required
                    />
                  </FormGroup>
                  <FormGroup className={classes.section}>
                    <Typography variant="body2" className={classes.formLabel}>
                      Description
                    </Typography>
                    <Field
                      component={TextField}
                      inputProps={{ className: classes.textField }}
                      disabled={initialValues.type === menuTypes.DELIVERY.value}
                      name="description"
                    />
                  </FormGroup>
                  <FormGroup className={classes.section}>
                    <Typography variant="body2" className={classes.formLabel}>
                      Menu type*
                    </Typography>
                    <Field
                      component={TextField}
                      inputProps={{ className: classes.textField }}
                      error={errorCountLabel !== null}
                      disabled={initialValues.type === menuTypes.DELIVERY.value}
                      name="type"
                      required
                      select
                    >
                      {Object.values(menuTypes)
                        .filter(
                          ({ value }) =>
                            value !== menuTypes.DELIVERY.value && value !== menuTypes.KIOSK.value,
                        )
                        .map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      {initialValues.type === menuTypes.DELIVERY.value && (
                        <MenuItem value={menuTypes.DELIVERY.value} disabled>
                          Sessions {menuTypes.DELIVERY.label}
                        </MenuItem>
                      )}
                    </Field>
                    <Typography
                      variant="body2"
                      className={`${classes.formLabel} ${classes.errorText}`}
                    >
                      {errorCountLabel && errorCountLabel}
                    </Typography>
                  </FormGroup>
                  {showOtterStoreIdField && (
                    <>
                      {deliverooStoreId && (
                        <FormGroup className={classes.section}>
                          <Typography variant="body2" className={classes.formLabel}>
                            Deliveroo restaurant ID
                          </Typography>
                          <Field
                            component={TextField}
                            inputProps={{ className: classes.textField }}
                            disabled={initialValues.type === menuTypes.DELIVERY.value}
                            name="deliverooStoreId"
                          />
                        </FormGroup>
                      )}
                      {ubereatsStoreId && (
                        <FormGroup className={classes.section}>
                          <Typography variant="body2" className={classes.formLabel}>
                            Uber Eats store ID
                          </Typography>
                          <Field
                            component={TextField}
                            inputProps={{ className: classes.textField }}
                            disabled={initialValues.type === menuTypes.DELIVERY.value}
                            name="ubereatsStoreId"
                          />
                        </FormGroup>
                      )}
                      {justeatStoreId && (
                        <FormGroup className={classes.section}>
                          <Typography variant="body2" className={classes.formLabel}>
                            Just Eat restaurant ID
                          </Typography>
                          <Field
                            component={TextField}
                            inputProps={{ className: classes.textField }}
                            disabled={initialValues.type === menuTypes.DELIVERY.value}
                            name="justeatStoreId"
                          />
                        </FormGroup>
                      )}
                      {type === menuTypes.DELIVERY.value && (
                        <FormGroup className={classes.section}>
                          <Typography variant="body2" className={classes.formLabel}>
                            Other fulfillment types
                          </Typography>
                          <Field
                            component={CheckboxWithLabel}
                            type="checkbox"
                            name="kioskEnabled"
                            Label={{ label: 'Kiosk' }}
                            disabled={!isAdmin()}
                          />
                        </FormGroup>
                      )}
                    </>
                  )}
                </Form>
              </DialogContent>
              <DialogActions className={classes.btnContainer}>
                <Button variant="outlined" onClick={onClose} className={classes.cancelBtn}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={classes.saveBtn}
                  color="inherit"
                  disabled={!dirty || errorCountLabel !== null}
                  onClick={() => handleSubmit(values)}
                  disableElevation
                >
                  {mode === 'CREATE' ? 'Add' : 'Update'}
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditMenu;
