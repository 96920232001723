import React, { useState } from 'react';
import { Button, CircularProgress, Popover } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { fetchVenueMenu, publishVenueMenu, fetchVenueMenuStatus } from '../../store/venueMenus';
import { getErrorMessage } from '../../shared/utils/errors';

const useStyles = makeStyles((theme) => ({
  popoverClass: {
    '& .MuiPaper-root': {
      padding: theme.spacing(1),
      marginTop: 8,
    },
  },
}));

const PublishMenu = ({ menuName, directIds = {} }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);

  const platforms = [
    directIds.deliverooStoreId && {
      name: 'deliveroo',
      displayName: 'Deliveroo',
    },
    directIds.ubereatsStoreId && {
      name: 'ubereats',
      displayName: 'Uber Eats',
    },
    directIds.justeatStoreId && {
      name: 'justeat',
      displayName: 'Just Eat',
    },
  ].filter(Boolean);

  const handleClose = () => {
    setDropdownOpen(false);
    setAnchorEl(undefined);
  };

  const handleClick = async (_event, target) => {
    setLoading(true);
    try {
      const targets = target ? [target] : platforms;
      await dispatch(
        publishVenueMenu(
          menuName,
          targets.map((t) => t.name),
        ),
      );
      setLoading(false);
      dispatch(fetchVenueMenu(menuName));
      dispatch(fetchVenueMenuStatus({ menuName }));
      showSuccessNotification(
        `Menu published${target ? ` to ${targets.map((t) => t.displayName).join(', ')}` : ''}`,
      );
      return null;
    } catch (e) {
      setLoading(false);
      showErrorNotification(getErrorMessage(e));
      return null;
    }
  };
  const isPublishDisabled = () => Object.keys(directIds).length === 0;

  return (
    <div style={{ display: 'flex' }}>
      <Button
        disabled={loading || isPublishDisabled()}
        color="primary"
        variant="contained"
        fullWidth
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setDropdownOpen(!dropdownOpen);
        }}
      >
        Publish {loading ? <CircularProgress size={20} /> : <ArrowDropDown />}
      </Button>
      {dropdownOpen && (
        <Popover
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          fullWidth
          className={classes.popoverClass}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Button
            id={menuName}
            variant="outlined"
            color="primary"
            fullWidth
            className={classes.dropdownButton}
            onClick={(e) => {
              handleClick();
              handleClose(e);
            }}
          >
            Publish all
          </Button>
          {platforms &&
            platforms.length > 0 &&
            platforms.map((target) => (
              <Button
                key={target.name}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={(e) => {
                  handleClick(e, target);
                  handleClose(e);
                }}
                style={{ width: '100%', marginTop: '.5em' }}
              >
                {` Publish to ${target.displayName}`}
              </Button>
            ))}
        </Popover>
      )}
    </div>
  );
};
export default PublishMenu;
