import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { WarningAmberOutlined } from '@mui/icons-material';
import { Link } from '@mui/material';
import { ReactComponent as InfoIcon } from '../assets/images/info.svg';
import { ReactComponent as WarningIconWhite } from '../assets/images/warning-icon-white.svg';
import CustomWarningBanner from '../components/CustomWarningBanner';
import { useNotifications } from '../shared/contexts/Notifications/useNotifications';
import { fetchStripeConnectAccount } from '../api/stripe';
import { isDeliveryOnly } from '../store/venues/selectors';

const BANNER_COLORS = {
  error: '#CA1D2A',
  warning: '#FFE87A',
  info: '#E0E0EB',
};

const useStripeStatusBanner = (paymentDetailsPage = true) => {
  const { showErrorNotification } = useNotifications();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const data = await fetchStripeConnectAccount();
        setAccountDetails(data);
      } catch (err) {
        if (err.response.data.errorCode !== 'STRIPE_ACCOUNT_NOT_FOUND')
          showErrorNotification(err.response.data);
      }
    };

    fetchAccountDetails();
  }, [showErrorNotification]);

  const {
    hasAcceptedTOS = false,
    hasOutstandingRequirements,
    dueDate,
    payoutsEnabled,
    status = '',
    hasPendingPayouts,
  } = accountDetails || {};

  const renderUpdateLink = (customStyles = {}) => {
    if (!paymentDetailsPage) {
      return (
        <Link
          sx={{ textDecoration: 'underline', ...customStyles }}
          underline="always"
          href="/settings/business/payout-details"
        >
          Update payout details
        </Link>
      );
    }
    return null;
  };

  let stripeStatusTitle;
  let stripeStatusContent;
  let statusIcon;
  let bannerColour;
  let titleColor;

  if (status === 'restricted' && !hasAcceptedTOS && !paymentDetailsPage) {
    if (hasPendingPayouts) {
      stripeStatusTitle = 'Your payout account details need updating';
      stripeStatusContent = (
        <p>
          We have identified some missing or incorrect information in your payout account. We
          can&apos;t process your payouts until this has been amended.{' '}
          {renderUpdateLink({ color: 'white' })}
        </p>
      );
      statusIcon = <WarningIconWhite />;
      bannerColour = BANNER_COLORS.error;
      titleColor = 'white';
    } else {
      stripeStatusTitle = 'You need to update your payout account details';
      stripeStatusContent = (
        <p>
          You will need to submit some details about your business. Once completed you will be able
          to receive payouts into your account every {typeDeliveryOnly ? 'wednesday' : 'weekday'}.{' '}
          {renderUpdateLink({ color: 'white' })}
        </p>
      );
      statusIcon = <WarningIconWhite />;
      bannerColour = BANNER_COLORS.error;
      titleColor = 'white';
    }
  } else if (status === 'pending' && paymentDetailsPage) {
    stripeStatusTitle = null;
    stripeStatusContent =
      'Hold tight, your payout details are being reviewed. We’ll email you when this is completed.';
    statusIcon = <InfoIcon />;
    bannerColour = BANNER_COLORS.info;
    titleColor = 'black';
  } else if (status === 'active' && !hasOutstandingRequirements) {
    // No banner to show
  } else if (
    (status === 'active' || status === 'restricted') &&
    hasOutstandingRequirements &&
    hasAcceptedTOS
  ) {
    stripeStatusTitle = 'Your payout account details need updating';
    stripeStatusContent =
      dueDate === null ? (
        <p>
          We need some more information from you to make sure you can continue to receive payouts.{' '}
          {renderUpdateLink()}
        </p>
      ) : (
        <p>
          We need some more information from you by the {dueDate} to make sure you can continue to
          receive payouts. {renderUpdateLink()}
        </p>
      );
    statusIcon = <WarningAmberOutlined />;
    bannerColour = BANNER_COLORS.warning;
    titleColor = 'black';
  } else if (status === 'restricted' && hasAcceptedTOS && !payoutsEnabled) {
    stripeStatusTitle = 'Your payout account details need updating';
    stripeStatusContent = (
      <p>
        We have identified some missing or incorrect information in your payout account. We
        can&apos;t process your payouts until this has been amended.{' '}
        {renderUpdateLink({ color: 'white' })}
      </p>
    );
    statusIcon = <WarningIconWhite />;
    bannerColour = BANNER_COLORS.error;
    titleColor = 'white';
  }

  const banner =
    stripeStatusTitle || stripeStatusContent ? (
      <CustomWarningBanner
        title={stripeStatusTitle}
        titleFont="smallBold"
        titleColor={titleColor}
        warningFont="small"
        backgroundColor={bannerColour}
        customIcon={statusIcon}
      >
        {stripeStatusContent}
      </CustomWarningBanner>
    ) : null;

  return {
    banner,
    accountDetails,
  };
};

export default useStripeStatusBanner;
