import React from 'react';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { Divider, Grid, IconButton, TextField, FormControlLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { MdDelete } from 'react-icons/md';
import { generateTimeOptions } from '../../../../shared/utils/generateDeliveryTimeOptions';
import { getSelectedTime } from '../../../../shared/utils/deliveryOpeningHours';
import isDesktop from '../../../../shared/utils/isDesktop';

const normaliseTime = (time) => {
  const [hour, minute] = time.split(':');
  const pHour = parseInt(hour, 10);
  const pMinute = parseInt(minute, 10);
  const nextDay =
    (pHour === 0 && pMinute >= 15) || (pHour > 0 && pHour < 5) || (pHour === 5 && pMinute === 0);

  return { hour: pHour, minute: pMinute, nextDay };
};

const handleFieldValueChange = ({ setFieldValue, dayIndex, segmentIndex, value, isTo = false }) => {
  const normalisedTime = normaliseTime(value.time);
  setFieldValue(
    `[${dayIndex}].openingHours[${segmentIndex}].${isTo ? 'toHour' : 'fromHour'}`,
    normalisedTime.hour,
  );
  setFieldValue(
    `[${dayIndex}].openingHours[${segmentIndex}].${isTo ? 'toMinute' : 'fromMinute'}`,
    normalisedTime.minute,
  );
  if (isTo)
    setFieldValue(`[${dayIndex}].openingHours[${segmentIndex}].nextDay`, normalisedTime.nextDay);
};

const useStyles = makeStyles((theme) => ({
  timeInput: {
    paddingRight: '4px',
  },
  dropdown: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 8,
    },
    '& .MuiFormControl-root': {
      borderRadius: 8,
    },
    '& .MuiInputBase-input': {
      height: '7px',
    },
  },
  formLabel: {
    alignItems: 'start',
    margin: 0,
    width: '100%',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: '#D0CFDF',
  },
}));

const TimeSegmentControl = ({
  previousSegment,
  segmentHours,
  segmentIndex,
  dayIndex,
  setFieldValue,
  disabled = false,
  handleRemoveSegment,
  openingHours,
}) => {
  const classes = useStyles();
  const { fromHour = 5, fromMinute = 0, toHour = 23, toMinute = 0 } = segmentHours || {};

  const nextSegmentOpeningTime = previousSegment
    ? moment({
        hour: previousSegment?.toHour,
        minute: previousSegment?.toMinute,
      }).add(15, 'minutes')
    : null;

  const openingTimeOptions = generateTimeOptions({
    currH: fromHour,
    currM: fromMinute,
    prevH: nextSegmentOpeningTime?.hours(),
    prevM: nextSegmentOpeningTime?.minutes(),
    endH: toHour,
    endM: toMinute,
  });

  const closingTimeOptions = generateTimeOptions({
    currH: toHour,
    currM: toMinute,
    prevH: fromHour,
    prevM: fromMinute,
  });

  const selectedTime = {
    openSegment: getSelectedTime(fromHour, fromMinute, openingTimeOptions),
    closeSegment: getSelectedTime(toHour, toMinute, closingTimeOptions),
  };

  const renderAutocomplete = (timeOptions, value, isTo) => (
    <Autocomplete
      options={timeOptions}
      getOptionLabel={(option) => `${option.time}${option.isNextDay ? ' (next day)' : ''}`}
      value={value}
      onChange={(_, val) => {
        if (!val) return;
        handleFieldValueChange({ setFieldValue, dayIndex, segmentIndex, value: val, isTo });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          disabled={disabled}
          inputProps={{
            ...params.inputProps,
            readOnly: !isDesktop,
          }}
        />
      )}
      getOptionDisabled={(_option, index) => index === 1}
      fullWidth
      disabled={disabled}
      className={classes.dropdown}
      slotProps={{
        paper: {
          sx: {
            marginTop: 1,
            '& .MuiAutocomplete-listbox': {
              padding: '8px',
            },
          },
        },
      }}
      ListboxProps={{
        sx: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            borderRadius: '8px',
            backgroundColor: '#F2F2F7',
          },
          '& .MuiAutocomplete-option:hover': {
            borderRadius: '8px',
            backgroundColor: '#F2F2F7',
          },
          '& .MuiAutocomplete-option.Mui-focused': {
            borderRadius: '8px',
            backgroundColor: '#F2F2F7',
          },
        },
      }}
    />
  );

  return (
    <Grid
      container
      key={segmentIndex}
      sx={{ display: isDesktop ? 'flex' : 'grid', ml: '47px', mt: '16px' }}
    >
      <Grid container>
        <Grid item xs={segmentIndex !== 0 ? 11 : 12}>
          <Grid container>
            <Grid item xs={12} md={6} className={classes.timeInput}>
              <FormControlLabel
                control={renderAutocomplete(
                  openingTimeOptions.slice(0, openingTimeOptions.length - 1),
                  selectedTime.openSegment,
                  false,
                )}
                label="Open"
                labelPlacement="top"
                className={classes.formLabel}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.timeInput}>
              <FormControlLabel
                control={renderAutocomplete(
                  closingTimeOptions.splice(1),
                  selectedTime.closeSegment,
                  true,
                )}
                label="Close"
                labelPlacement="top"
                className={classes.formLabel}
              />
            </Grid>
          </Grid>
        </Grid>

        {segmentIndex !== 0 && (
          <Grid item xs={1} display="flex" justifyContent="end">
            <IconButton
              onClick={handleRemoveSegment}
              disabled={disabled}
              disableRipple
              sx={{
                p: '24px 0 0',
              }}
            >
              <MdDelete color="#5A7296" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {segmentIndex < openingHours.length - 1 && (
        <Grid item xs={12}>
          <Divider variant="fullWidth" className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};

export default TimeSegmentControl;
