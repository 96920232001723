import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  IconButton,
  TextField,
  Grid,
  Autocomplete,
  Slider,
  Typography,
  Chip,
  Tooltip,
  Paper,
  InputAdornment,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { LoyaltyOutlined, Search, RestartAlt, Storefront } from '@mui/icons-material';
import copy from 'clipboard-copy';

import { isEqual, pick } from 'lodash';
import TableLink from '../../components/TableLink';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { deleteMenuItem, fetchMenuItems, updateMenuItemsAvailability } from '../../store/menuItems';
import { getErrorMessage } from '../../shared/utils/errors';
import { getMenuItemsState } from '../../store/menuItems/selectors';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import OrderableTable from '../../components/OrderableTable';
import UniversalSave from '../../components/UniversalSave';
import useRoles from '../../hooks/useRoles';
import { filterDataItems } from '../../shared/utils/filterData';
import useSearch from '../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  box: {
    borderRadius: theme.spacing(0.5),
    padding: '6px 12px 0 0',
  },
  searchBar: {
    display: 'flex',
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '15rem',
    },
    '& .MuiInputLabel-outlined': {
      color: '#5A7296',
      top: '-7px',
    },
    '& .MuiInputBase-input': {
      padding: '8.5px 14px',
    },
  },
  searchBox: {
    padding: theme.spacing(0.75, 0),
  },
  slider: {
    margin: theme.spacing(0, 3),
    height: theme.spacing(2),
    borderRadius: '2px',
    '& .MuiSlider-thumb': {
      width: theme.spacing(0.5),
      height: theme.spacing(5.5),
      borderRadius: '2px',
    },
    '& .MuiSlider-rail': {
      backgroundColor: '#D8D8D8',
      opacity: 'unset',
    },
    '& .MuiSlider-valueLabel': {
      height: '44px',
      borderRadius: '100px',
      padding: '12px 16px',
      backgroundColor: 'black',
    },
  },
  dropdownWidths: {
    width: 'auto',
    minWidth: '127px',
    '& .MuiInputBase-root': {
      display: 'inline-flex',
      flexWrap: 'nowrap',
      borderRadius: '2px',
    },
  },
  itemLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  readonlyChip: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
}));

const FormObserver = ({ setFieldValue, setValueData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setValueData(values);
  }, [values, dirty, setFieldValue, setValueData]);
  return null;
};

const findArrayIndex = (valueData, row) =>
  valueData.findIndex((obj) => obj.itemId === `${row.itemId}`);

const MenuItems = () => {
  const classes = useStyles();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const dispatch = useDispatch();
  const menuItemsState = useSelector(getMenuItemsState);
  const { loading, data, error } = menuItemsState;

  const categories = data ? [...new Set(data?.map((item) => item.category))] : [];
  const ingredientTypes = ['Drink', 'Food'];
  const availabilityTypes = ['Available', 'Unavailable'];
  const itemPrices = useMemo(() => {
    if (!data || data.length === 0) return [0, 500000];
    const flatItemPrices = data?.flatMap((items) => items.itemPrices);
    return [0, Math.max(...flatItemPrices)];
  }, [data]);
  const initialFilters = useMemo(
    () => [
      {
        types: [],
        categories: [],
        available: [],
        itemPrices,
      },
    ],
    [itemPrices],
  );
  const sessionStorageFilters =
    JSON.parse(sessionStorage.getItem('menuItemsFilter')) || initialFilters;
  const [filters, setFilters] = useState(sessionStorageFilters || []);
  const [sliderValues, setSliderValues] = useState(sessionStorageFilters[0].itemPrices);
  const [valueData, setValueData] = useState(data);
  const { isAdmin } = useRoles();
  const searchKeys = useMemo(() => ['itemName', 'label', 'category', 'type'], []);
  const threshold = 0.3;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
  );

  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef(null);
  const [initialValues, setInitialValues] = useState([]);

  const handleAvailableChange = async (values) => {
    const changedValues = values.filter((item, index) => !isEqual(item, initialValues[index]));

    try {
      await dispatch(updateMenuItemsAvailability({ values: changedValues }));
      showSuccessNotification('Menu items availability updated successfully');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const handleDelete = useCallback(
    async (itemId) => {
      try {
        await dispatch(deleteMenuItem(itemId));
        showSuccessNotification('Item has been deleted successfully');
        dispatch(fetchMenuItems());
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          label: item.label || item.itemName,
          brandName: item.brandName || '',
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'itemName',
            'label',
            'category',
            'type',
            'available',
            'itemPrices',
            'brandName',
            'itemId',
            'delete',
            'readonly',
            'inhouseOnly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'itemName':
          return (
            <Box className={classes.itemLabelContainer}>
              {row.readonly ? (
                <>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                  <Chip label="Read Only" size="small" />
                  {row.inhouseOnly && (
                    <Chip
                      label="Inhouse only"
                      icon={<Storefront fontSize="16px" />}
                      size="small"
                      sx={{ ml: 1 }}
                    />
                  )}
                </>
              ) : (
                <TableLink to={`/menu-items/${row.itemId}`}>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                </TableLink>
              )}
            </Box>
          );
        case 'label':
          return <Typography variant="subtitle">{value || row.itemName}</Typography>;
        case 'delete':
          return (
            <Box sx={isAdmin() ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
              {isAdmin() && (
                <Tooltip title="Copy item ID" disableInteractive>
                  <IconButton
                    size="small"
                    onClick={() => {
                      copy(row.itemId);
                    }}
                    sx={{ margin: '0 5px' }}
                  >
                    <LoyaltyOutlined sx={{ width: 18, height: 18 }} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                edge="end"
                size="small"
                onClick={() => handleDelete(row.itemId)}
                disabled={row?.readonly}
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        case 'itemPrices':
          return row.itemPrices.map((i) => `£${i}`).join(', ');
        case 'available':
          if (valueData) {
            const index = findArrayIndex(valueData, row);
            const isChecked = valueData[index]?.available || false;
            return (
              <Field
                name={`[${index}].available`}
                component={Switch}
                checked={isChecked}
                value={!isChecked}
                color="primary"
                type="checkbox"
              />
            );
          }
          return null;
        default:
          return value;
      }
    },
    [classes.itemLabelContainer, handleDelete, isAdmin, valueData],
  );

  const handleFilterChange = (_e, values, filterName) => {
    if (!values) return;
    if (filterName === 'itemPrices') {
      setSliderValues(values);
    } else if (!JSON.parse(sessionStorage.getItem('menuItemsFilter'))) {
      setSliderValues(itemPrices);
    }

    const existingFilterData = sessionStorageFilters ? sessionStorageFilters[0] : initialFilters[0];

    const updatedFilter = [
      {
        ...existingFilterData,
        [filterName]: values,
      },
    ];

    setFilters(updatedFilter);
    sessionStorage.setItem('menuItemsFilter', JSON.stringify(updatedFilter));
    setFilteredItems(filterDataItems(updatedFilter, searchResults || data));
    dispatch(fetchMenuItems());
  };

  const handleTempSliderChange = (_e, newValue) => {
    setSliderValues(newValue);
  };

  const handleDropdownClose = (event, reason) => {
    if (reason === 'selectOption' || reason === 'blur') {
      return;
    }
    setOpen(false);
  };

  const handleClickOutside = (event) => {
    if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const compareItemPrices = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);
  const showResetButton =
    !compareItemPrices(sessionStorageFilters[0].itemPrices, itemPrices) &&
    !compareItemPrices(sliderValues, itemPrices);

  useEffect(() => {
    if (shouldLoad(menuItemsState)) dispatch(fetchMenuItems());
    setFilteredItems(filterDataItems(filters, searchResults || data));
  }, [data, dispatch, filters, menuItemsState, searchResults, setFilteredItems]);

  useEffect(() => {
    if (data) {
      const initialFormValues = newData();
      setInitialValues(initialFormValues);
    }
  }, [data, newData]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Page loading={loading} error={error} fullWidth>
      <Paper sx={{ borderRadius: 4, mt: 4 }}>
        <Grid sx={{ padding: '16px 16px 0 16px' }}>
          <Grid container>
            <Grid item xs={12} className={classes.searchBox}>
              <TextField
                helperText={searchError}
                className={classes.searchBar}
                id="outlined-basic"
                label="Search"
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {data && data.length > 0 && filters && (
            <Grid container sx={{ flexWrap: 'nowrap', overflowY: 'auto' }}>
              <Grid item className={classes.box}>
                <Autocomplete
                  fullWidth
                  multiple
                  id="availability_type"
                  options={availabilityTypes}
                  label="Status"
                  defaultValue={
                    sessionStorageFilters && sessionStorageFilters[0].available.length > 0
                      ? sessionStorageFilters[0].available
                      : []
                  }
                  value={
                    sessionStorageFilters && sessionStorageFilters[0].available.length > 0
                      ? sessionStorageFilters[0].available
                      : []
                  }
                  onChange={(_e, values) => handleFilterChange(_e, values, 'available')}
                  disableCloseOnSelect
                  className={classes.dropdownWidths}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Status"
                      className={classes.dropdownWidths}
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.box}>
                <Autocomplete
                  fullWidth
                  multiple
                  id="ingredient_type"
                  options={ingredientTypes}
                  defaultValue={
                    sessionStorageFilters && sessionStorageFilters[0].types.length > 0
                      ? sessionStorageFilters[0].types
                      : []
                  }
                  value={
                    sessionStorageFilters && sessionStorageFilters[0].types.length > 0
                      ? sessionStorageFilters[0].types
                      : []
                  }
                  onChange={(_e, values) => handleFilterChange(_e, values, 'types')}
                  disableCloseOnSelect
                  className={classes.dropdownWidths}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Type"
                      className={classes.dropdownWidths}
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.box}>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={4}
                  id="categories_autocomplete"
                  options={categories}
                  defaultValue={
                    sessionStorageFilters && sessionStorageFilters[0].categories.length > 0
                      ? sessionStorageFilters[0].categories
                      : []
                  }
                  value={
                    sessionStorageFilters && sessionStorageFilters[0].categories.length > 0
                      ? sessionStorageFilters[0].categories
                      : []
                  }
                  onChange={(_e, values) => handleFilterChange(_e, values, 'categories')}
                  disableCloseOnSelect
                  className={classes.dropdownWidths}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Category"
                      className={classes.dropdownWidths}
                    />
                  )}
                />
              </Grid>
              <Grid item className={classes.box}>
                <div ref={autocompleteRef}>
                  <Autocomplete
                    fullWidth
                    id="price-autocomplete"
                    options={['']}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={handleDropdownClose}
                    className={classes.dropdownWidths}
                    slotProps={{
                      paper: {
                        sx: {
                          width: 300,
                        },
                      },
                    }}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option': {
                          '&.Mui-focused': {
                            backgroundColor: 'transparent',
                          },
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={
                          !JSON.parse(sessionStorage.getItem('menuItemsFilter'))
                            ? 'Price'
                            : `£${sliderValues[0]} - £${sliderValues[1]}`
                        }
                        className={classes.dropdownWidths}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                        InputLabelProps={{
                          shrink: false,
                        }}
                      />
                    )}
                    renderOption={(props) => (
                      <Box
                        {...props}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                        }}
                      >
                        <Box
                          component="li"
                          sx={{
                            width: '100%',
                            height: '64px',
                            mt: '50px',
                            display: 'flex',
                          }}
                        >
                          <Slider
                            className={classes.slider}
                            aria-labelledby="price-range-slider"
                            getAriaLabel={(index) => `Price ${index === 0 ? 'minimum' : 'maximum'}`}
                            getAriaValueText={(value) => `£${value}`}
                            valueLabelDisplay="on"
                            valueLabelFormat={(value) => `£${value}`}
                            step={itemPrices[1] > 1000 ? 100 : 1}
                            min={0}
                            max={itemPrices[1] || 100}
                            name="price-range"
                            defaultValue={sliderValues}
                            value={sliderValues}
                            onChangeCommitted={(_e, newValue) =>
                              handleFilterChange(_e, newValue, 'itemPrices')
                            }
                            onChange={handleTempSliderChange}
                            disabled={data && data.length === 0}
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                            disableSwap
                          />
                        </Box>
                        {showResetButton && (
                          <Box
                            component="li"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleFilterChange(e, itemPrices, 'itemPrices');
                            }}
                            onMouseDown={(e) => e.stopPropagation()}
                            sx={{ display: 'flex', alignSelf: 'start' }}
                          >
                            <>
                              <Box sx={{ textDecoration: 'underline', pr: 1 }}>Reset </Box>
                              <Box>
                                <RestartAlt />
                              </Box>
                            </>
                          </Box>
                        )}
                      </Box>
                    )}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ padding: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No menu items found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {filteredItems && filteredItems.length > 0 && (
              <Card>
                <Formik initialValues={[...newData()]}>
                  {({ setFieldValue, values, dirty, resetForm, errors, isValid }) => (
                    <>
                      <UniversalSave
                        isValid={isValid}
                        errors={errors}
                        dirty={dirty}
                        onSave={() => handleAvailableChange(values)}
                        onDiscard={resetForm}
                      />
                      <Form>
                        <FormObserver setFieldValue={setFieldValue} setValueData={setValueData} />
                        <OrderableTable
                          tableData={[...newData()]}
                          titles={[
                            'INTERNAL NAME',
                            'ITEM NAME',
                            'CATEGORY',
                            'TYPE',
                            'AVAILABLE',
                            'PRICES',
                            'BRAND',
                            '',
                          ]}
                          keys={['itemId']}
                          excludeFields={['itemId', 'readonly']}
                          disableColumnTitles={['PRICES', '', 'BRAND']}
                          values={values}
                          valueFormatter={valueFormatter}
                        />
                      </Form>
                    </>
                  )}
                </Formik>
              </Card>
            )}
          </>
        )}
      </Paper>
    </Page>
  );
};

export default withVenue(MenuItems);
