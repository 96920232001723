import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import PhotoUploadChooser from './PhotoUploadChooser';
import PhotoUploadCropper from './PhotoUploadCropper';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import PhotoUploadGallery from './PhotoUploadGallery';
import { uploadPhoto } from '../../api/imageGallery';
import { fetchBrand } from '../../store/brands/brand';

const PhotoUploadDialog = ({
  handleCloseDialog,
  isOpen,
  imgUploadUrl,
  imgUploadUrlOriginal,
  imgUrl,
  itemId,
  hasImage,
  localFile,
  saveLocalFile,
  handleSuccessfulUpload,
  isBrands,
  isCategoryImage,
  isBrandLogo,
  isBrandHero,
  isKioskCarouselImage,
  menuId,
  brandId,
  isMenuItemPage,
}) => {
  const dispatch = useDispatch();
  const [showChooser, setShowChooser] = useState(true);
  const [showCropper, setShowCropper] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const [localImageFile, setLocalImageFile] = useState(null);
  const [localImageFileOriginal, setLocalImageFileOriginal] = useState(null);
  const showCroppedImage = (file) => {
    setLocalImageFileOriginal(file);
    setLocalImageFile(file);
    setShowChooser(false);
    setShowCropper(true);
    setShowGallery(false);
  };

  const showGalleryChooser = () => {
    setShowChooser(false);
    setShowCropper(false);
    setShowGallery(true);
  };

  let title = 'Product Image';
  if (isBrandLogo) {
    title = '';
  }
  if (isCategoryImage) {
    title = 'Category Image';
  }

  const handleUpload = useCallback(
    async (image) => {
      try {
        if (imgUploadUrlOriginal && !!localImageFileOriginal) {
          const localImageFileOriginalBlob = localImageFileOriginal.slice(
            0,
            localImageFileOriginal.size,
            localImageFileOriginal.type,
          );
          localImageFileOriginalBlob.name = localImageFileOriginal.name;
          await uploadPhoto(imgUploadUrlOriginal, localImageFileOriginalBlob);
          dispatch(fetchBrand(brandId));
        }

        await uploadPhoto(imgUploadUrl, image);
        dispatch(fetchBrand(brandId));
        showSuccessNotification(
          `${localImageFile?.name || image.name} has been uploaded successfully`,
        );
        handleCloseDialog(true);
        if (handleSuccessfulUpload) handleSuccessfulUpload();
      } catch (error) {
        showErrorNotification(getErrorMessage(error));
      }
    },
    [
      imgUploadUrlOriginal,
      localImageFileOriginal,
      imgUploadUrl,
      dispatch,
      brandId,
      showSuccessNotification,
      localImageFile?.name,
      handleCloseDialog,
      handleSuccessfulUpload,
      showErrorNotification,
    ],
  );

  useEffect(() => {
    if (localFile && saveLocalFile) handleUpload(localFile);
  }, [localFile, saveLocalFile, handleUpload]);

  return (
    <Dialog onClose={handleCloseDialog} open={isOpen} fullWidth maxWidth="md">
      {showChooser && (
        <PhotoUploadChooser
          handleCloseDialog={handleCloseDialog}
          imgUrl={imgUrl}
          handleLocalFile={showCroppedImage}
          handleShowGallery={showGalleryChooser}
          itemId={itemId}
          hasImage={hasImage}
          isBrands={isBrands}
          isCategoryImage={isCategoryImage}
          isBrandLogo={isBrandLogo}
          isBrandHero={isBrandHero}
          isKioskCarouselImage={isKioskCarouselImage}
          menuId={menuId}
          brandId={brandId}
          isMenuItemPage={isMenuItemPage}
        />
      )}
      {showCropper && (
        <PhotoUploadCropper
          handleCloseDialog={handleCloseDialog}
          file={localImageFile}
          handleUpload={handleUpload}
          title={title}
          aspectRatio={isCategoryImage || isBrandLogo ? 1 / 1 : 4 / 3}
          isCategoryImage={isCategoryImage}
          isBrandLogo={isBrandLogo}
        />
      )}
      {showGallery && (
        <PhotoUploadGallery
          handleCloseDialog={handleCloseDialog}
          itemId={itemId}
          isBrands={isBrands}
        />
      )}
    </Dialog>
  );
};

PhotoUploadDialog.propTypes = {
  handleCloseDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default PhotoUploadDialog;
