import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pick } from 'ramda';

import { makeStyles } from '@mui/styles';
import { Chip, Grid, IconButton, Paper, Typography } from '@mui/material';

import { MdClose } from 'react-icons/md';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';

import {
  clearMenuItem,
  clearMenuItems,
  fetchMenuItem,
  updateMenuItem,
} from '../../store/menuItems';
import { getMenuItemState } from '../../store/menuItems/selectors';
import { getIngredientsState } from '../../store/ingredients/selectors';
import { fetchIngredients } from '../../store/ingredients';
import { fetchModifiers } from '../../store/modifiers';

import Page from '../../components/Page';
import MenuItemForm from '../../components/MenuItemForm';
import IngredientsList from '../../components/IngredientsList';
import ModifiersList from '../../components/ModifiersList';
import withVenue from '../../hoc/withVenue';
import { isBrandUp } from '../../store/venues/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import { getModifiersState } from '../../store/modifiers/selectors';
import BackArrow from '../../components/BackArrow';
import PageHeader from '../../components/PageHeader';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  heading: {
    margin: '15px 0',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: '10px',
  },
}));

const buildInitialValues = (localMenuItem) => ({
  ...{ dietaryRequirements: [] },
  ...{ discountId: localMenuItem?.discount?.discountId || '' },
  ...{ label: localMenuItem?.label || localMenuItem?.itemName },
  ...pick(
    [
      'imgUrl',
      'originalImgUrl',
      'imgUploadUrl',
      'imgOriginalUploadUrl',
      'thumbUrl',
      'thumbUploadUrl',
      'vatRate',
      'itemName',
      'itemLabel',
      'itemId',
      'itemOptions',
      'category',
      'type',
      'description',
      'available',
      'isAlcoholic',
      'hidden',
      'dietaryRequirements',
      'hasImage',
      'imageApprovalStatus',
      'discount',
      'snoozeUntil',
      'readonly',
    ],
    localMenuItem,
  ),
});

const MenuItem = ({ redirect, itemId, showCloseButton = false, handleDialogOpen }) => {
  const params = useParams();
  const menuItemId = params.menuItemId || itemId;
  const classes = useStyles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();
  const isBrandup = useSelector(isBrandUp);

  const [loadRefresh, setLoadRefresh] = useState(false);
  const menuItemState = useSelector(getMenuItemState);
  const ingredientsState = useSelector(getIngredientsState);
  const modifiersState = useSelector(getModifiersState);
  const { loading: loadingItem, data: menuItem, error: errorItem } = menuItemState;
  const {
    loading: loadingIngredients,
    data: ingredients,
    error: errorIngredients,
  } = ingredientsState;

  const initialValues = useMemo(() => buildInitialValues(menuItem || {}), [menuItem]);

  useEffect(() => {
    if (shouldLoad(ingredientsState)) dispatch(fetchIngredients());
    if (shouldLoad(modifiersState)) dispatch(fetchModifiers());
    if (shouldLoad(menuItemState)) dispatch(fetchMenuItem(menuItemId));
  }, [menuItemId, dispatch, ingredientsState, modifiersState, menuItemState]);

  const refresh = useCallback(async () => {
    await dispatch(fetchMenuItem(menuItemId));
    setLoadRefresh(false);
  }, [menuItemId, dispatch]);

  const refreshItem = useCallback(() => {
    setLoadRefresh(true);
    setTimeout(refresh, 2000);
  }, [refresh]);

  const handleReorderOptions = async (values) => {
    try {
      await dispatch(updateMenuItem({ menuItemId, values }));
      showSuccessNotification('The list has been re-ordered successfully');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updateMenuItem({ menuItemId, values }));
      showSuccessNotification('Menu Item has been updated successfully');
      dispatch(clearMenuItems());
      redirect();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
      redirect();
    }
  };

  return (
    <div className={showCloseButton ? classes.container : ''}>
      <PageHeader fullWidth>
        {showCloseButton ? (
          <IconButton onClick={handleDialogOpen} size="small">
            <MdClose />
          </IconButton>
        ) : (
          <BackArrow to="/items" text="Items" />
        )}
        {menuItem && (
          <Typography className={classes.heading} variant="h2" component="h1">
            {`${menuItem.category}: ${menuItem.itemName}`}
            {menuItem.readonly && <Chip label="Read-only" />}
          </Typography>
        )}
      </PageHeader>
      <Page
        loading={loadingItem || loadingIngredients || loadRefresh}
        error={errorItem || errorIngredients}
        fullWidth
      >
        {menuItem && ingredients && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={isBrandup ? 12 : 8}>
                <MenuItemForm
                  initialValues={initialValues}
                  onSubmit={handleOnSubmit}
                  onReorderOptions={handleReorderOptions}
                  refreshItem={refreshItem}
                  isMenuItemPage
                />
              </Grid>
              {!isBrandup && (
                <Grid item xs={12} sm={4}>
                  <Paper className={classes.paper}>
                    <ModifiersList menuItemId={menuItemId} readonly={menuItem.readonly} />
                  </Paper>
                  <Paper className={classes.paper}>
                    <IngredientsList menuItemId={menuItemId} readonly={menuItem.readonly} />
                  </Paper>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Page>
    </div>
  );
};

export default withVenue(MenuItem, null, clearMenuItem);
