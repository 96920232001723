import { pick } from 'ramda';
import { localDaysAllWeekOpen } from '../store/utils/servingTimes';
import { get, patch, post, del, putSimple } from './httpRequest';
import menuTypes from '../shared/constants/menuTypes';

export const fetchVenueMenus = async () => {
  const response = await get(`/menus`);
  return response.data;
};

export const fetchVenueMenu = async (menuName) => {
  const response = await get(`/menus/${menuName}`);

  return response.data;
};

export const createVenueMenu = async (data) => {
  const finalData = {
    ...pick(['displayMenuName', 'description', 'type'], data),
    schedule: localDaysAllWeekOpen,
  };

  if (finalData.description === '') delete finalData.description;

  const response = await post(`/menus`, { data: finalData });
  return response.data;
};

export const importVenueMenu = async () => {
  const response = await post(`/menus/import`);
  return response.data;
};

export const updateVenueMenu = async ({ menuName, values }) => {
  const finalData = {
    ...pick(
      [
        'schedule',
        'displayMenuName',
        'active',
        'description',
        'type',
        'otterStoreId',
        'otterDeliveryEnabled',
        'otterPickupEnabled',
        'kioskEnabled',
      ],
      values,
    ),
  };

  const isDeliveryMenu =
    finalData.type === menuTypes.DELIVERY.value ||
    finalData.type === menuTypes.DELIVERY_INHOUSE.value;

  if ((finalData.otterStoreId === '' && isDeliveryMenu) || !isDeliveryMenu) {
    delete finalData.otterStoreId;
  }
  if (finalData.description === '') delete finalData.description;

  await patch(`/menus/${menuName}`, {
    data: finalData,
  });
};

export const deleteVenueMenu = async (menuName) => {
  const response = await del(`/menus/${menuName}`);

  return response.data;
};

export const duplicateVenueMenu = async ({ menuName, type }) => {
  const response = await get(`/menus/${menuName}/duplicate?type=${type}`);

  return response.data;
};

export const publishVenueMenu = async (menuName, directPublishTargets) => {
  const response = await post(`/menus/${menuName}/publish`, {
    data: {
      directPublishTargets,
    },
  });

  return response.data;
};

export const fetchMenuStatus = async (venueMenu) => {
  const response = await get(`/menus/${venueMenu.menuName}/status`);
  return response.data;
};

export const updateVenueMenuItems = async (menuName, items) => {
  const menuItems = await patch(`/menus/${menuName}`, {
    data: {
      items,
    },
  });
  return menuItems.data;
};

export const uploadSpreadsheet = async (uploadUrl, file) => {
  const response = await putSimple(uploadUrl, file, {
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  });
  return response.data;
};
